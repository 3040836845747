<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../../assets/img/home/scienceService/banner.jpg" alt="" />
    </div>
    <!-- <div class="breadcrumb_box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/enterpriseService' }"
          >基础服务</el-breadcrumb-item
        >
        <el-breadcrumb-item>科技服务</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->
    <div class="container_title">多元化网站服务，按需定制</div>

    <div class="serviceSup">
      <div class="serviceSup_item">
        <div class="supitem_left">
          <img
            src="../../../assets/img/home//scienceService/定制.png"
            style="width: 80%; height: 80%"
            alt=""
          />
        </div>
        <div class="supitem_right">
          <div class="supitem_right_title">品牌官网定制</div>
          <div class="supitem_right_dsc">
            国际化设计水平，突出品牌定位;专属定制，满足企业品牌与营销相融合的多功能网站需求;
          </div>
        </div>
      </div>

      <div class="serviceSup_item">
        <div class="supitem_left">
          <img
            src="../../../assets/img/home//scienceService/模态框.png"
            style="width: 80%; height: 80%"
            alt=""
          />
        </div>
        <div class="supitem_right">
          <div class="supitem_right_title">集团网站开发</div>
          <div class="supitem_right_dsc">
            大气视觉设计，升级品牌形象，展现集团实力;统一后台界面，管理同体系下不同网站;
          </div>
        </div>
      </div>

      <div class="serviceSup_item">
        <div class="supitem_left">
          <img
            src="../../../assets/img/home//scienceService/响应式.png"
            style="width: 80%; height: 80%"
            alt=""
          />
        </div>
        <div class="supitem_right">
          <div class="supitem_right_title">响应式网站</div>
          <div class="supitem_right_dsc">
            全网多终端适配+多浏览器兼容，打造极致用户体验,
          </div>
        </div>
      </div>

      <div class="serviceSup_item">
        <div class="supitem_left">
          <img
            src="../../../assets/img/home//scienceService/insight-exercise forcast.png"
            style="width: 80%; height: 80%"
            alt=""
          />
        </div>
        <div class="supitem_right">
          <div class="supitem_right_title">营销型网站</div>
          <div class="supitem_right_dsc">
            以用户核心，注重用户交互体验，实时访客识别，助力营销精准性，促进网站转化;
          </div>
        </div>
      </div>
    </div>

    <div class="centermaxbox">
      <div class="container_title">功能推荐</div>
      <div class="functionRE">
        <div class="serviceSup_item">
          <div class="supitem_left">
            <img
              src="../../../assets/img/home//scienceService/gntj1.png"
              alt=""
            />
          </div>
          <div class="supitem_right">
            <div class="supitem_right_title">数据统计分析</div>
            <div class="supitem_right_dsc">
              多维度网站数据统计分析，优化品牌运营策略
            </div>
          </div>
        </div>

        <div class="serviceSup_item">
          <div class="supitem_left">
            <img
              src="../../../assets/img/home//scienceService/gntj2.png"
              alt=""
            />
          </div>
          <div class="supitem_right">
            <div class="supitem_right_title">多语言支持</div>
            <div class="supitem_right_dsc">
              支持中、英、日等13种语言，提升品牌形象
            </div>
          </div>
        </div>

        <div class="serviceSup_item">
          <div class="supitem_left">
            <img
              src="../../../assets/img/home/scienceService/gntj3.png"
              alt=""
            />
          </div>
          <div class="supitem_right">
            <div class="supitem_right_title">留言板</div>
            <div class="supitem_right_dsc">
              访客互动优质渠道，提升网站活跃度
            </div>
          </div>
        </div>

        <div class="serviceSup_item">
          <div class="supitem_left">
            <img
              src="../../../assets/img/home/scienceService/gntj4.png"
              alt=""
            />
          </div>
          <div class="supitem_right">
            <div class="supitem_right_title">第三方登录支持</div>
            <div class="supitem_right_dsc">
              支持开通微信/00/微博，第三方便捷登录
            </div>
          </div>
        </div>

        <div class="serviceSup_item">
          <div class="supitem_left">
            <img
              src="../../../assets/img/home/scienceService/gntj5.png"
              alt=""
            />
          </div>
          <div class="supitem_right">
            <div class="supitem_right_title">消息通知</div>
            <div class="supitem_right_dsc">
              访客/会员动态即时触达，提高网站互动效率
            </div>
          </div>
        </div>

        <div class="serviceSup_item">
          <div class="supitem_left">
            <img
              src="../../../assets/img/home/scienceService/gntj6.png"
              alt=""
            />
          </div>
          <div class="supitem_right">
            <div class="supitem_right_title">网站备份</div>
            <div class="supitem_right_dsc">支持网站手动备份，定期自动备份</div>
          </div>
        </div>

        <div class="serviceSup_item">
          <div class="supitem_left">
            <img
              src="../../../assets/img/home/scienceService/gntj7.png"
              alt=""
            />
          </div>
          <div class="supitem_right">
            <div class="supitem_right_title">HTTPS安全</div>
            <div class="supitem_right_dsc">避免被劫持，保护用户隐私</div>
          </div>
        </div>

        <div class="serviceSup_item">
          <div class="supitem_left">
            <img
              src="../../../assets/img/home/scienceService/gntj8.png"
              alt=""
            />
          </div>
          <div class="supitem_right">
            <div class="supitem_right_title">网站操作监控</div>
            <div class="supitem_right_dsc">24小时网站操作，安全监控</div>
          </div>
        </div>
      </div>

      <div class="container_title">特色功能</div>
      <div class="functionRE">
        <div class="serviceSup_item">
          <div class="supitem_left">
            <img
              src="../../../assets/img/home/scienceService/tsgn1.png"
              alt=""
            />
          </div>
          <div class="supitem_right">
            <div class="supitem_right_title">网页版小程序</div>
            <div class="supitem_right_dsc">
              支持一键生成网页版小程序、微信、百度双端
            </div>
          </div>
        </div>

        <div class="serviceSup_item">
          <div class="supitem_left">
            <img
              src="../../../assets/img/home/scienceService/tsgn2.png"
              alt=""
            />
          </div>
          <div class="supitem_right">
            <div class="supitem_right_title">知识付费</div>
            <div class="supitem_right_dsc">
              支持文章、视频、音频、专栏付费查看，流量变现
            </div>
          </div>
        </div>

        <div class="serviceSup_item">
          <div class="supitem_left">
            <img
              src="../../../assets/img/home/scienceService/tsgn3.png"
              alt=""
            />
          </div>
          <div class="supitem_right">
            <div class="supitem_right_title">AI系统</div>
            <div class="supitem_right_dsc">
              AI智能生成TDK，智能翻译、智能提取文章关键词等，高效运营
            </div>
          </div>
        </div>

        <div class="serviceSup_item">
          <div class="supitem_left">
            <img
              src="../../../assets/img/home/scienceService/tsgn4.png"
              alt=""
            />
          </div>
          <div class="supitem_right">
            <div class="supitem_right_title">成员权限</div>
            <div class="supitem_right_dsc">
              支持添加多个成员账号，设置不同的管理权限
            </div>
          </div>
        </div>

        <div class="serviceSup_item">
          <div class="supitem_left">
            <img
              src="../../../assets/img/home/scienceService/tsgn5.png"
              alt=""
            />
          </div>
          <div class="supitem_right">
            <div class="supitem_right_title">多产品询盘</div>
            <div class="supitem_right_dsc">
              可通过表单设置多产品列表询盘常用于外贸网站
            </div>
          </div>
        </div>

        <div class="serviceSup_item">
          <div class="supitem_left">
            <img
              src="../../../assets/img/home/scienceService/tsgn6.png"
              alt=""
            />
          </div>
          <div class="supitem_right">
            <div class="supitem_right_title">代码插入</div>
            <div class="supitem_right_dsc">
              可通过代码插入方式，在当前页面实现特定效果
            </div>
          </div>
        </div>

        <div class="serviceSup_item">
          <div class="supitem_left">
            <img
              src="../../../assets/img/home/scienceService/tsgn7.png"
              alt=""
            />
          </div>
          <div class="supitem_right">
            <div class="supitem_right_title">客服系统接入</div>
            <div class="supitem_right_dsc">
              7*24小时在线互动客服，解答用户疑问
            </div>
          </div>
        </div>

        <div class="serviceSup_item">
          <div class="supitem_left"></div>
          <div class="supitem_right"></div>
        </div>
      </div>
    </div>

    <div class="serviceflow" style="color: #fff">
      <div class="container_title one_title">网站建设开发流程</div>

      <div class="serviceflow_box">
        <div class="serviceflow_item">
          <div class="serviceflow_item_bg">
            <img
              src="../../../assets/img/home/scienceService/kflc1.png"
              alt=""
            />
          </div>
          <div class="supitem_right_title">项目规划</div>
        </div>

        <div class="serviceflow_item">
          <div class="serviceflow_item_bg">
            <img
              src="../../../assets/img/home/scienceService/kflc2.png"
              alt=""
            />
          </div>
          <div class="supitem_right_title">视觉设计</div>
        </div>

        <div class="serviceflow_item">
          <div class="serviceflow_item_bg">
            <img
              src="../../../assets/img/home/scienceService/kflc3.png"
              alt=""
            />
          </div>
          <div class="supitem_right_title">前端制作</div>
        </div>

        <div class="serviceflow_item">
          <div class="serviceflow_item_bg">
            <img
              src="../../../assets/img/home/scienceService/kflc4.png"
              alt=""
            />
          </div>
          <div class="supitem_right_title">后台开发</div>
        </div>

        <div class="serviceflow_item">
          <div class="serviceflow_item_bg">
            <img
              src="../../../assets/img/home/scienceService/kflc5.png"
              alt=""
            />
          </div>
          <div class="supitem_right_title">循环测试</div>
        </div>

        <div class="serviceflow_item">
          <div class="serviceflow_item_bg">
            <img
              src="../../../assets/img/home/scienceService/kflc6.png"
              alt=""
            />
          </div>
          <div class="supitem_right_title">交付培训</div>
        </div>
      </div>
    </div>

    <div class="process_btn" @click="handleApply">立即咨询</div>

    <!-- 一键办理弹出框 -->
    <el-dialog
      title="预留信息"
      :visible.sync="dialogVisible"
      width="25%"
      center
      :before-close="dialogColse"
    >
      <div class="dialog_head">
        <span>我们的客户经理将在1个工作日内与您联系！</span>
      </div>
      <div class="register_phone">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="10px"
          class="demo-ruleForm"
        >
          <el-form-item prop="reserveName">
            <el-input
              prefix-icon="el-icon-user"
              v-model="ruleForm.reserveName"
              placeholder="请输入称呼昵称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reservePhone">
            <el-input
              prefix-icon="el-icon-mobile-phone"
              v-model="ruleForm.reservePhone"
              maxlength="11"
              placeholder="请输入联系方式"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reserveEntName">
            <el-input
              prefix-icon="el-icon-office-building"
              v-model="ruleForm.reserveEntName"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="remark">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2 }"
              v-model="ruleForm.remark"
              placeholder="备注......(200字以内)"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提交预约</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>

    <el-dialog :visible.sync="dialogSuccess" width="25%" center>
      <div class="success_icon">
        <img src="../../../assets/img/success.png" alt="" />
      </div>
      <div class="success_title">提交成功</div>
      <div class="success_content">
        <p>随后工作人员将与您联系，请保持电话畅通。</p>
        <p>咨询热线：<span>400-666-3369</span></p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addReserve } from "../../../api/reserve";
export default {
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      registerPhone: "",
      formInline: {
        address: "太原",
        phone: "",
        acctName: "",
      },

      dialogSuccess: false,
      dialogVisible: false,
      registerPhone: "",
      ruleForm: {
        reserveName: "",
        reservePhone: "",
        reserveEntName: "",
        remark: "",
      },
      rules: {
        reserveName: [
          { required: true, message: "请输入预约人名称", trigger: "blur" },
          { min: 2, max: 5, message: "长度在 2 到 5 个字符", trigger: "blur" },
        ],
        reservePhone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    onSubmit() {
      this.dialogVisible = true;
    },
    // 体验信息提交
    handleApply() {
      this.dialogVisible = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.reserveType = "06";
          addReserve(this.ruleForm).then((response) => {
            // if (response.code == 200) {
            //   // this.$modal.msgSuccess("意见办理注册预约成功");
            // }
            this.dialogSuccess = true;
            this.dialogVisible = false;
          });
        }
      });
    },
    dialogColse() {
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.banner {
  width: 100%;
  height: 566px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.breadcrumb_box {
  width: 1200px;
  padding: 30px 0 0;
  margin: 0 auto;
}

.container {
  padding: 60px;
}

.container_title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.serviceSup {
  width: 1200px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 3% auto;
}

.serviceSup_item {
  width: 50%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.serviceSup_item .supitem_left {
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2%;
  flex: 1;
}

.serviceSup_item .supitem_right {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex: 5;
}

.supitem_right_title {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
}

.supitem_right_dsc {
  width: 90%;
  text-align: left;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #949494;
  margin-top: 10px;
}

.centermaxbox {
  width: 100%;
  /* height: 800px; */
  background-color: #f5f5f5;
  padding-top: 2px;
  padding-bottom: 40px;
}

.functionRE {
  width: 1200px;
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  flex-wrap: wrap;
}

.functionRE .serviceSup_item {
  width: 23%;
  background: #fff;
  margin: 10px 10px;
  padding: 6px;
}

.functionRE .supitem_left {
  /* width: 43px;
    height:43px; */
  background: #fff;
}

.serviceflow {
  width: 100%;
  height: 350px;
  background-color: #055afe;
}

.serviceflow_box {
  width: 70%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.serviceflow_item {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.serviceflow_item .supitem_right_title {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  margin-top: 8%;
}

.serviceflow_item_bg {
}

.process_btn {
  width: 132px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 28px;
  background: #055afe;
  cursor: pointer;
  color: #fff;
  margin: 0 auto;
  margin-top: 60px;
}

.one_title {
  margin-top: 0;
}

.el-form {
  text-align: center;
}
.dialog_head {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #055afe;
  margin-bottom: 20px;
}
.success_icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.success_icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.success_title {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin: 30px 0;
}

.success_content {
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #919191;
}
.success_content p span {
  color: #055afe;
}
</style>
